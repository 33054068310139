export default {
  data() {
    return {
      selectedTab: null,
    }
  },
  computed: {
    hasWidgets() {
      return !!(this.page && this.page.widgets && this.page.widgets.length)
    },
    hasTabs() {
      return this.tabs.length > 0
    },
    allTabs() {
      return [
        {
          name: this.page.name,
          id: '0',
          anchor: '',
        },
        ...this.tabs,
      ]
    },
    tabs() {
      if (this.hasWidgets) {
        const tabWidget = this.page.widgets.find(
          (widget) => widget.type === 'tabs'
        )
        return (tabWidget && tabWidget.data) || []
      }
      return []
    },
    currentTab() {
      return this.tabs.find((t) => t.anchor === this.selectedTab)
    },
    pageContent() {
      return (
        (this.currentTab && this.currentTab.content) ||
        (this.page && this.page.content)
      )
    },
  },
  watch: {
    $route(to, from) {
      this.selectedTab = to.hash ? to.hash.substring(1) : ''
    },
  },
  created() {
    this.selectedTab = this.$route.hash.substring(1)
  },
  methods: {
    locationFromTab(tab) {
      return {
        ...(tab.id !== '0' && { hash: tab.anchor }),
      }
    },
  },
}
