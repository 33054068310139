<template>
  <PageLayoutWrapper
    page="pages/_id"
    class="outline-none"
  >
    <div aria-live="polite" class="visually-hidden">
      Navigated to {{ currentPageLabel }}.
    </div>
    <h1 v-if="hasTabs">{{ page.name }}</h1>
    <div
      :class="{'lg:flex lg:flex-row': navigationLayout === 'vertical'}"
    >
      <template v-if="hasTabs">
        <div
          v-if="customPageNavigation && navigationLayout === 'vertical'"
          class="mt-8 lg:mt-0 mb-10 lg:mb-0 lg:mr-10 lg:w-48 flex-shrink-0"
          data-test="verticalNav"
        >
          <h3 class="uppercase border-b border-theme-400 sidebar-header">Subpages</h3>
          <ul role="tablist">
            <li
              v-for="tab in allTabs"
              :key="tab.id"
              :aria-selected="selectedTab === tab.anchor ? 'true' : 'false'"
              :aria-controls="`panel-${tab.anchor}`"
              role="tab"
            >
              <nuxt-link
                :to="locationFromTab(tab)"
                class="bg-white inline-block text-blue-600 ui-font my-1"
              >
                {{ tab.name }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <ul v-else-if="enableNonScrollableSubNavigation" class="flex flex-row flex-wrap px-4 md:px-8">
          <li
            v-for="tab in allTabs"
            :key="tab.id"
            class="-mb-px mr-1"
          >
            <nuxt-link
              v-if="selectedTab !== tab.anchor"
              :to="locationFromTab(tab)"
              class="bg-white inline-block py-2 px-2 md:px-4 text-blue-600 font-semibold ui-font">
                {{tab.name}}
            </nuxt-link>
            <span
              v-else
              class="current-tab bg-white inline-block py-2 px-2 md:px-4 text-blue-600 font-bold"
            >
              {{ tab.name }}
            </span>
          </li>
        </ul>
        <HorizontalScroll v-else class="mt-12 mb-8" :hideArrows="allTabs.length <= 1">
          <div class="flex w-full tab-list" role="tablist">
            <div
              v-for="tab in allTabs"
              :key="tab.id"
              class="mr-1 flex-shrink-0 w-auto text-center list-item"
            >
              <nuxt-link
                :aria-selected="selectedTab === tab.anchor ? 'true' : 'false'"
                :aria-controls="selectedTab === tab.anchor ? 'tab-content-panel' : null"
                role="tab"
                :to="locationFromTab(tab)"
                class="bg-white inline-block px-2 md:px-4 text-blue-600 font-semibold ui-font my-1"
                :class="{ underline: tab.anchor === selectedTab }"
              >
                {{ tab.name }}
              </nuxt-link>
            </div>
          </div>
        </HorizontalScroll>
      </template>
      <RichContent
        id="tab-content-panel"
        :content="pageContent"
        role="tabpanel"
      />
    </div>
  </PageLayoutWrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  NON_SCROLLABLE_SUB_NAVIGATION,
  CUSTOM_PAGE_CONFIGURABLE_NAVIGATION,
} from '@/constants/featureFlags'
import useFeatureFlags from '@/composables/useFeatureFlags'
import PageLayoutWrapper from '@/components/PageLayoutWrapper'
import HorizontalScroll from '@/components/HorizontalScroll'
import pageLayout from '@/mixins/pageLayoutMixin'
import tabsMixin from '@/mixins/tabsMixin'
import RichContent from '@/components/RichContent'
import { metaTags, metaTitle } from '@/utils/meta'

export default {
  components: { PageLayoutWrapper, RichContent, HorizontalScroll },
  mixins: [pageLayout, tabsMixin],
  data() {
    return {
      page: null,
    }
  },
  setup() {
    const {
      enableNonScrollableSubNavigation,
      customPageNavigation,
    } = useFeatureFlags({
      enableNonScrollableSubNavigation: NON_SCROLLABLE_SUB_NAVIGATION,
      customPageNavigation: CUSTOM_PAGE_CONFIGURABLE_NAVIGATION,
    })
    return { enableNonScrollableSubNavigation, customPageNavigation }
  },
  computed: {
    ...mapState('settings', ['settings']),
    ...mapGetters('settings', ['catalogWideMetaTitle']),
    ...mapGetters('page', ['currentPageLabel']),
    navigationLayout() {
      return this.settings.customPageNavigationLayout
    },
  },
  created() {
    this.$store.commit('page/SET_CURRENT_PAGE_LABEL', this.page?.name)
  },
  watch: {
    currentPageLabel () {
      document.querySelector('#main-content').focus()
    }
  },
  head() {
    return {
      ...metaTitle(
        this.page?.metafields,
        this.catalogWideMetaTitle,
        this.page?.name
      ),
      meta: metaTags(this.page?.metafields, {
        description: this.page?.name,
      }),
    }
  },
}
</script>

<style lang="css">
h1:first-child + div > div:first-child#content > h1:first-child.page-title {
  display: none;
}
</style>
<style scoped>
.ui-font {
  font-weight: var(--interfaceFontWeight, inherit);
  color: var(--interfaceFontColor, inherit);
}

.tab-list {
  margin-bottom: 0 !important;
}

.list-item {
  /* limiting width of list item to 85% so that longer text is fully visible
  within the viewport specially on mobile screen */
  max-width: 85%;
}

.sidebar-header {
  @apply text-lg mb-3 pb-3 text-theme-400;
}
</style>
