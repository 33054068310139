<template>
  <div class="relative grid">
    <button
      v-if="!hideArrows"
      v-show="showLeftBtn"
      :aria-hidden="!showLeftBtn"
      aria-label="Scroll tabs left"
      data-test="left-scroll-button"
      class="absolute bg-white rounded-full border left-0 btn-left flex items-center justify-center"
      @click="scrollContent('left')"
    >
      <BaseIcon
        icon="angle-left"
        class="text-gray-600"
      />
    </button>
    <div
      ref="contentWrapper"
      data-test="scroll-wrapper"
      class="overflow-x-auto overflow-y-hidden scroll-wrapper"
      @scroll="handleScroll()"
      @click="adjustScrollPosition"
      @keyup.tab="adjustScrollPosition"
    >
      <slot />
    </div>
    <button
      v-if="!hideArrows"
      v-show="showRightBtn"
      :aria-hidden="!showRightBtn"
      aria-label="Scroll tabs right"
      data-test="right-scroll-button"
      class="absolute bg-white rounded-full border right-0 btn-right flex items-center justify-center"
      @click="scrollContent()"
    >
      <BaseIcon
        icon="angle-right"
        class="text-gray-600"
      />
    </button>
  </div>
</template>

<script>
import { computed, ref, onMounted, onDeactivated } from '@vue/composition-api'
import { debounce } from '@/utils/generalUtils'

export default {
  props: {
    displacement: {
      type: Number,
      default: 0.6, // 60%
    },
    hideArrows: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const contentWrapper = ref(null) // template ref
    const leftScrollAmount = ref(0)
    const contentWrapperScrollWidth = ref(0)
    const contentWrapperOffsetWidth = ref(0)
    const scrollDistance = ref(0)
    // consider the space and shadow of left/right button
    const btnSpace = ref(50)

    function handleResize() {
      contentWrapperScrollWidth.value = contentWrapper.value.scrollWidth
      contentWrapperOffsetWidth.value = contentWrapper.value.offsetWidth
    }

    onMounted(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
    })

    onDeactivated(() => {
      window.removeEventListener('resize', handleResize)
    })

    const showLeftBtn = computed(() => {
      return contentWrapper.value && leftScrollAmount.value > 0
    })
    const showRightBtn = computed(() => {
      return (
        contentWrapper.value &&
        leftScrollAmount.value + contentWrapperOffsetWidth.value <
          contentWrapperScrollWidth.value
      )
    })

    function scrollContent(direction) {
      scrollDistance.value = Math.floor(
        contentWrapper.value.clientWidth * props.displacement
      )

      if (direction === 'left') {
        // scroll to left
        contentWrapper.value.scrollLeft -= scrollDistance.value
      } else {
        // scroll to right
        contentWrapper.value.scrollLeft += scrollDistance.value
      }
    }

    const handleScroll = debounce(() => {
      /* track scrollLeft to dynamically show/hide scroll buttons */
      leftScrollAmount.value = contentWrapper.value.scrollLeft
    }, 100)

    function adjustScrollPosition(evt) {
      const containerRect = contentWrapper.value.getBoundingClientRect()
      const elementRect = evt.target.getBoundingClientRect()

      // no need to adjust scroll if the element is fully visible
      if (isElementFullyVisible(elementRect, containerRect)) return

      const newDistance = elementRect.left - containerRect.left
      /* btnSpace: makes sure the item is not placed under the shadow
      of the left button */
      contentWrapper.value.scrollLeft =
        leftScrollAmount.value + newDistance - btnSpace.value
    }

    function isElementFullyVisible(elementRect, containerRect) {
      /* btnSpace: to make sure the item under the button/shadow is
      considered as partially hidden and scroll adjustment is triggered */
      return (
        elementRect.left - btnSpace.value > containerRect.left &&
        elementRect.right + btnSpace.value < containerRect.right
      )
    }

    return {
      contentWrapper,
      showLeftBtn,
      showRightBtn,
      scrollContent,
      handleScroll,
      adjustScrollPosition,
    }
  },
}
</script>

<style scoped>
.scroll-wrapper {
  scroll-behavior: smooth;
}

.scroll-wrapper::-webkit-scrollbar {
  display: none;
}

/*Hide scrollbar for IE, Edge and Firefox*/
.scroll-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.btn-left,
.btn-right {
  height: 1.75rem;
  width: 1.75rem;
  top: calc(50% - 0.875rem);
  box-shadow: 0px 0px 20px 20px white;
}
</style>
